export default [
      {
        path: "finance",
        name: "account.finance",
        component: () => import("@/views/Finance/Finance"),
        meta: {
          pageTitle: "Finance",
          rule: "owner",
        },
        children: [
          {
            path: "analytics",
            component: () => import("@/views/Finance/FinanceAnalytics"),
            name: "account.finance.analytics",
            meta: {
              pageTitle: "Finance analytics",
              parent: "account.finance",
              rule: "owner",
            },
          },
          {
            path: "invoice",
            component: () => import("@/views/Finance/FinanceInvoice"),
            name: "account.finance.invoice",
            meta: {
              pageTitle: "Create invoice",
              parent: "account.finance",
              rule: "owner",
            },
          },
        ],
      },
    ];
