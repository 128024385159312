const router = [
  {
    path: "embedding",
    name: "embedding",
    component: () => import("@/views/Embedding/Embedding.vue"),
    meta: {
      pageTitle: "Embedding",
      rule: "owner",
    },
    children: [
      {
        path: "players",
        component: () => import("@/views/Embedding/Players/Players.vue"),
        children: [
          {
            path: "",
            name: "players",
            component: () => import("@/views/Embedding/Players/PlayerList.vue"),
            meta: {
              pageTitle: "Web Players",
              rule: "owner",
            },
          },
          {
            path: "create",
            component: () => import("@/views/Embedding/Players/PlayerCreate"),
            name: "player.create",
            props: true,
            meta: {
              pageTitle: "Create Player",
              parent: "players",
              rule: "owner",
            },
          },
          {
            path: ":playerKey",
            component: () => import("@/views/Embedding/Players/Player.vue"),
            name: "player",
            props: true,
            meta: {
              pageTitle: "Player info",
              parent: "players",
              rule: "owner",
            },
          },
        ],
      },
    ],
  },
]

export default router
