<template>
  <div>
    <div ref="colorpicker" class="input-group color-picker flex gap-1 w-full">
      <vs-input class="w-full" v-bind="$attrs" @focus="showPicker" @input="updateFromInput" />

      <span v-if="color" class="input-group-addon color-picker-container">
        <span :style="colorBoxStyle" class="current-color" @click="togglePicker"></span>
      </span>

      <div style="position: relative">
        <chrome-picker v-if="pickerVisible" :value="colors" class="vc-right" @input="updateFromPicker" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color"

export default {
  name: "UIColorPicker",

  components: {
    "chrome-picker": Chrome,
  },

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      pickerType: "chrome",
      colors: { hex: "#000000" },
      displayPicker: false,
      debounceTimeout: null,
    }
  },

  computed: {
    colorBoxStyle() {
      return {
        backgroundColor: this.colors.hex,
        borderRadius: "4px",
      }
    },

    pickerVisible() {
      return this.pickerType === "chrome" && this.displayPicker
    },
  },

  methods: {
    showPicker() {
      document.addEventListener("click", this.handleDocumentClick)
      this.displayPicker = true
    },

    hidePicker() {
      document.removeEventListener("click", this.handleDocumentClick)
      this.displayPicker = false
    },

    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },

    updateFromInput(color) {
      this.updateColors(color)
      this.$emit("input", color)
    },

    updateFromPicker(color) {
      this.colors = color
      this.$emit("input", this.getColorValue(color))
    },

    getColorValue(color) {
      if (color.rgba.a === 1) {
        return color.hex
      }
      const { r, g, b, a } = color.rgba
      return `rgba(${r}, ${g}, ${b}, ${a})`
    },

    updateColors(color) {
      if (color.startsWith("#")) {
        this.colors = { hex: color }
      } else if (color.startsWith("rgba")) {
        const [r, g, b, a] = color.match(/\d+(\.\d+)?/g).map(Number)
        this.colors = {
          hex: `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`,
          a,
        }
      }
    },

    handleDocumentClick(e) {
      if (!this.$refs.colorpicker.contains(e.target)) {
        this.hidePicker()
      }
    },
  },

  watch: {
    color: {
      immediate: true,
      handler(newColor) {
        if (newColor) {
          this.updateColors(newColor)
        }
      },
    },
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick)
  },
}
</script>

<style scoped>
.vc-right {
  right: 0;
}

.color-picker {
  position: relative;
  display: flex;
  width: 100%;
}

.vc-chrome {
  position: absolute;
  top: 42px;
  z-index: 100;
}

.current-color {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
