import axios from "axios"

const toolsClient = axios.create({
  baseURL: process.env.VUE_APP_TOOLS_SERVICE_URL + "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
})

toolsClient.interceptors.request.use(
  (config) => {
    const token = window.auth?.token

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    console.log("[TOOLS] Request error", error.message, error.response)
    return Promise.reject(error)
  }
)

toolsClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Server responded with an error:", error.response)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }

    return Promise.reject(error)
  }
)

export default toolsClient
